<template>
  <div id="apipe-cloud-course-page">
    <top-nav-new />

    <div class="apipe-cloud-course-container-case">
      <div class="top-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h2>客户案例</h2>
          <div class="tit">共创共赢，携手前行</div>
        </div>
      </div>
      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="">
          <h4>客户案例</h4>
          <div class="tit">共创共赢，携手前行</div>
        </div>
      </div>

      <div class="pc-case container d-none d-lg-block">
        <!-- <h5 class="pro-tit">全部案例{{ seach }}</h5>
        <div class="aa">123456</div> -->
        <div class="catcary">
          <!-- <div class="catcary-wrap" v-for="(item, index) in list" :key="index">
            <div class="left">
              <div class="indus">{{ item.type }}</div>
              <span
                :class="['all', seach.indus == item.tag ? 'act' : '']"
                @click="handleAll1(item.tag)"
              >
                {{ item.tag }}
              </span>
            </div>
            <div class="types" v-if="item.tags.length">
              <div
                :class="['tgs', seach.indus == itm.tit ? 'act' : '']"
                @click="handleTag1(itm.tit)"
                v-for="(itm, idx) in item.tags"
                :key="idx"
              >
                {{ itm.tit }}
              </div>
            </div>
          </div> -->
          <div>
            <!-- <div class="catcary-wrap" v-for="(x, y) in list2" :key="y">
              <div class="left">
                <div class="indus">{{ x.type }}</div>
                <span
                  :class="['all', seach.pro == x.tag ? 'act' : '']"
                  @click="handleAll2(x.tag)"
                >
                  {{ x.tag }}
                </span>
              </div>
              <div class="types" v-if="x.tags">
                <div
                  :class="['tgs', seach.pro == itm.tit ? 'act' : '']"
                  @click="handleTag2(itm.tit)"
                  v-for="(itm, idx) in x.tags"
                  :key="idx"
                >
                  {{ itm.tit }}
                </div>
              </div>
            </div> -->
          </div>

          <div class="content container" v-if="listcot.length">
            <div class="content-warp row row-cols-1 row-cols-lg-3 g-2 g-lg-3">
              <div
                class="itms col"
                v-for="(item, index) in listcot"
                :key="index"
              >
                <div class="cot p-3">
                  <div v-if="item.tag != 'more'">
                    <img :src="item.img" alt="" style="width: 100%" />
                    <div class="tit">{{ item.tit }}</div>
                    <div class="des">{{ item.des }}</div>
                    <el-tag effect="plain" type="info" size="small">{{
                      item.tag
                    }}</el-tag>
                  </div>
                  <div
                    v-else
                    style="
                      line-height: 370px;
                      text-align: center;
                      color: #333333;
                    "
                  >
                    {{ item.des }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="pageNation container">
          <div class="preAndNext">
            <div
              :class="['before', current == 1 ? 'dis' : 'activ']"
              @click="lunboleft"
            >
              <span class="el-icon-arrow-left"></span>
            </div>
            <div class="nums">{{ current }} / {{ maxPage }}</div>
            <div
              :class="['after', current == maxPage ? 'dis' : 'activ']"
              @click="lunboright"
            >
              <span class="el-icon-arrow-right"></span>
            </div>
          </div>
        </div> -->
      </div>

      <div class="mobile-case d-block d-lg-none">
        <div class="opt">
          <!-- <div class="indus" @click.stop="handleIndus">
            <span>所属行业（全部）</span>
            <span class="el-icon-arrow-down"></span>
            <div v-show="showIndus" class="boader">
              <div
                class="itms"
                @click="handles(item.tit)"
                v-for="(item, index) in tags1"
                :key="index"
              >
                {{ item.tit }}
              </div>
            </div>
          </div> -->
          <!-- <div class="prod" @click.stop="handlePro">
            <span>所属产品（全部）</span>
            <span class="el-icon-arrow-down"></span>
            <div v-show="showPro" class="boader">
              <div
                class="itms"
                @click="handleSelectPro(item.tit)"
                v-for="(item, index) in tags2"
                :key="index"
              >
                {{ item.tit }}
              </div>
            </div>
          </div> -->
        </div>
        <div class="catcary">
          <div class="content">
            <div class="content-warp">
              <div class="itms" v-for="(item, index) in listcot" :key="index">
                <div class="cot" v-if="item.tag != 'more'">
                  <img :src="item.img" alt="" style="width: 100%" />
                  <div class="tit">{{ item.tit }}</div>
                  <div class="des">{{ item.des }}</div>
                  <el-tag effect="plain" type="info" size="small">{{
                    item.tag
                  }}</el-tag>
                </div>
                <div
                  v-else
                  style="line-height: 370px; text-align: center; color: #333333"
                >
                  {{ item.des }}
                </div>

                <!-- <div class="cot">
                  <div class="tit">123{{ item.createDate }}</div>
                  <div class="des">{{ item.contentTitle }}</div>
                  <el-tag  effect="plain" type="info">MES</el-tag>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="pageNation container" v-if="false">
          <div class="preAndNext">
            <div
              :class="['before', current == 1 ? 'dis' : 'activ']"
              @click="lunboleft"
            >
              <span class="el-icon-arrow-left"></span>
            </div>
            <div class="nums">{{ current }} / {{ maxPage }}</div>
            <div
              :class="['after', current == maxPage ? 'dis' : 'activ']"
              @click="lunboright"
            >
              <span class="el-icon-arrow-right"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <NewFooter />
    <MobileFooter class="d-block d-lg-none" />
  </div>
</template>
      
<script>
import { Newslist } from "@/api/fetch";
import Qs from "qs";
export default {
  name: "hardware",
  data() {
    return {
      cur: 1,
      activeName: "1",
      idx: 0,
      ids: -1,
      list: [
        {
          type: "所属行业",
          tag: "全部",
          tags: [
            // {
            //   tit: "管道行业",
            // },
            // {
            //   tit: "综合集团",
            // },
            // {
            //   tit: "政府国企",
            // },
            // {
            //   tit: "教育行业",
            // },
          ],
        },
      ],
      tags1: [
        {
          tit: "全部",
        },
        {
          tit: "管道行业",
        },
        {
          tit: "综合集团",
        },
        {
          tit: "政府国企",
        },
        {
          tit: "教育行业",
        },
      ],
      list2: [
        {
          type: "所属产品",
          tag: "全部",
          tags: [
            // {
            //   tit: "伟班APP",
            // },
            // {
            //   tit: "MES",
            // },
            // {
            //   tit: "WMS",
            // },
            // {
            //   tit: "ERP",
            // },
            // {
            //   tit: "CRM",
            // },
            // {
            //   tit: "PLM",
            // },
            // {
            //   tit: "SCM",
            // },
          ],
        },
      ],
      tags2: [
        {
          tit: "全部",
        },
        {
          tit: "伟班APP",
        },
        {
          tit: "MES",
        },
        {
          tit: "WMS",
        },
        {
          tit: "ERP",
        },
        {
          tit: "CRM",
        },
        {
          tit: "PLM",
        },
        {
          tit: "SCM",
        },
      ],
      funList2: [
        {
          tit: "华瀚",
          img: require("@/assets/newSite/solution/c1.png"),
        },
        {
          tit: "锦瀚",
          img: require("@/assets/newSite/solution/c2.png"),
        },
        {
          tit: "华润集团",
          img: require("@/assets/newSite/solution/c3.png"),
        },
        {
          tit: "中科院",
          img: require("@/assets/newSite/solution/c1.png"),
        },
        {
          tit: "华润集团",
          img: require("@/assets/newSite/solution/c2.png"),
        },
      ],
      listcot: [
        {
          img: require("@/assets/newSite/case/s1.png"),
          tit: "管道深汕工厂-智能制造项目",
          tag: "MES",
          des: "智能制造生产过程全面实现数字化和信息化；还将产品、设备和企业等各环节进行网络化、互联互通。通过物联网技术和5G的推进，实现设备之间的数据共享、协同工作，及时了解生产流程和设备状态，提高了生产效率和品质。",
        },
        {
          img: require("@/assets/newSite/case/s2.png"),
          tit: "管道深汕工厂-企业资源管理",
          tag: "ERP",
          des: "强调对企业管理的事前控制能力，把设计，制造，销售，采购，运输，仓储及人力资源，决策支持等方面工作看作一个动态可事前控制的有机整体，全面调度企业资源调配和利用，提供对产品质量，市场变化等关键信息实时分享。",
        },
        {
          img: require("@/assets/newSite/case/s3.png"),
          tit: "华瀚科技深汕工厂数字化营销管理",
          tag: "CRM",
          des: "对组织营销引流、销售管理和服务管理进行统一平台化管理，拉通LTC流程，通过对商机的全流程跟踪和数据分析，给销售人员提供有效的决策支撑，对交付过程的各个节点进行详尽的追踪和展示",
        },
        {
          img: require("@/assets/newSite/case/s4.png"),
          tit: "华瀚投资控股-仓储管理",
          tag: "WMS",
          des: "通过引入条码标签、RFID等标识物，真正实现了账实一致，提高库存数据准确度，应用智能硬件技术，引入AVG，自动导轨等设备，进一步提升了仓储的作业效率，为柔性供应链提供强力支撑。",
        },
        {
          img: require("@/assets/newSite/case/s5.png"),
          tit: "华瀚投资控股-日报系统",
          tag: "日报系统",
          des: "日报管理系统是一种用于管理个人或团队工作日报的软件系统。日报管理系统可以提高工作效率、减少人工错误、提高数据准确性和安全性、提高个人或团队绩效等。",
        },
        {
          img: require("@/assets/newSite/case/s6.png"),
          tit: "华瀚投资控股-会议系统",
          tag: "会议系统",
          des: "会议管理系统是一种用于管理组织内部会议的软件系统，会议管理系统的优点包括提高会议效率、 提升会议质量，确保会议决策顺利，提升参会人员满意度",
        },
        {
          img: require("@/assets/newSite/case/s7.png"),
          tit: "华瀚投资控股-费用管控",
          tag: "费控系统",
          des: "通过对报销流程，费用申请流程和付款流程的在线化管理，整合开票和财务系统，实现了费用管控的无纸化、自动化和高效率。",
        },
        {
          img: require("@/assets/newSite/case/s8.png"),
          tit: "华瀚投资控股-HRM项目",
          tag: "HRM系统",
          des: "HRM系统是一种用于管理企业或组织内部人力资源的软件系统，也称为人力资源管理系统（HRMS），HR系统可以帮助组织更好地管理人力资源，提高管理效率。",
        },
        {
          img: require("@/assets/newSite/case/s9.png"),
          tit: "华瀚投资控股-智慧考勤",
          tag: "考勤系统",
          des: "智慧考勤，人性化的考勤数字化方案，全面支持多端移动设备，统一实现协同办公，智能移动化，集成企业财务、薪酬系统，一键生成考勤账表，解放人力。",
        },
        {
          img: require("@/assets/newSite/case/s10.png"),
          tit: "华瀚投资控股-薪酬管理项目",
          tag: "薪酬系统",
          des: "薪酬管理系统是一种用于管理组织内部薪酬的软件系统，薪酬管理系统可以提高薪酬管理效率、减少人工错误、提高数据准确性和安全性、提高员工满意度等。",
        },
        {
          img: require("@/assets/newSite/case/s11.png"),
          tit: "华瀚投资控股-项目管理",
          tag: "项目管理",
          des: "提供从项目前期调研规划、项目创建、立项审批，计划任务制定、项目执行反馈及风险管控，计划变更、实施，验收，交付物归档、项目成本管控、项目过程监控、项目验收结案的全生命周期的数字化项目管理。",
        },
        {
          img: require("@/assets/newSite/case/s12.png"),
          tit: "管道深汕工厂-供应链管理",
          tag: "SCM",
          des: "通过供应链数字化转型途径，增加预测的准确性；减少库存，提高发货供货能力；减少工作流程周期，提高生产率，降低供应链成本；减少总体采购成本，缩短生产周期，加快市场响应速度，真正赋能企业降本增效。",
        },
        {
          img: require("@/assets/newSite/case/s13.png"),
          tit: "管道深汕工厂-产品研发管理",
          tag: "PLM",
          des: "以提高企业的运作管理效率和规范化、提高新产品开发能力和提升产品开发质量、提升产品研发的项目管理水平、实现企业致力资源安全高效管理为目标，以产品研发项目管理和产品数据管理为重点，实现产品研发项目流程。",
        },
        {
          img: require("@/assets/newSite/case/s14.png"),
          tit: "华瀚投资控股-文档管理",
          tag: "DMS",
          des: "主要解决企业全面提升团队协作效率，企业数字知识产权财富积累以及文档安全管理，同时降低成本，实现更准确的决策，更高效的管理。",
        },
        {
          img: require("@/assets/newSite/case/s15.png"),
          tit: "华瀚投资控股-伟班(OA)",
          tag: "伟班(OA)",
          des: "伟班平台是一种企业级应用集成及支持服务平台，为企业提供了可配置的各种业务流程管理和协同办公解决方案，帮助企业实现业务流程自动化管理、即时通讯、通讯录及待办管理等，提高工作效率，为企业发展提供了有力的支持。",
        },
        {
          img: require("@/assets/newSite/case/s16.png"),
          tit: "华瀚投资控股-智慧园区项目",
          tag: "智慧园区",
          des: "园区一张网，实现多种场景应用下的一网统管，将智慧停车，智慧门禁，智慧安防，智慧消防，智慧管网，智慧视频，智慧电梯等园区物联应用进行一网联合，实现数字化管理；为园区高效运营提供有利的抓手。",
        },
        {
          img: require("@/assets/newSite/case/s17.png"),
          tit: "华瀚投资控股-综合运营监管大脑",
          tag: "运营大脑",
          des: "数字化时代的企业，就好比一艘高速飞行的战机，对每一个企业主来说，拥有一个展现企业经营数据的驾驶舱势在必得。",
        },
        {
          img: require("@/assets/newSite/case/s17.png"),
          tit: "华瀚投资控股",
          tag: "more",
          des: "更多合作案例持续更新中…",
        },
      ],
      listContent: [
        {
          tit: "案例一",
          img: require("@/assets/newSite/case/case1.png"),
          des: "通过MES的工单管理功能，实现生产过程的数字化和智能化管理，提高生产效率和质量，降低成本，优化资源配置，提高客户满意度。",
        },
        {
          tit: "案例一",
          img: require("@/assets/newSite/case/case1.png"),
          des: "通过MES的工单管理功能，实现生产过程的数字化和智能化管理，提高生产效率和质量，降低成本，优化资源配置，提高客户满意度。",
        },
        {
          tit: "案例一",
          img: require("@/assets/newSite/case/case1.png"),
          des: "通过MES的工单管理功能，实现生产过程的数字化和智能化管理，提高生产效率和质量，降低成本，优化资源配置，提高客户满意度。",
        },
        {
          tit: "案例一",
          img: require("@/assets/newSite/case/case1.png"),
          des: "通过MES的工单管理功能，实现生产过程的数字化和智能化管理，提高生产效率和质量，降低成本，优化资源配置，提高客户满意度。",
        },
        {
          tit: "案例一",
          img: require("@/assets/newSite/case/case1.png"),
          des: "通过MES的工单管理功能，实现生产过程的数字化和智能化管理，提高生产效率和质量，降低成本，优化资源配置，提高客户满意度。",
        },
        {
          tit: "案例一",
          img: require("@/assets/newSite/case/case1.png"),
          des: "通过MES的工单管理功能，实现生产过程的数字化和智能化管理，提高生产效率和质量，降低成本，优化资源配置，提高客户满意度。",
        },
        {
          tit: "案例一",
          img: require("@/assets/newSite/case/case1.png"),
          des: "通过MES的工单管理功能，实现生产过程的数字化和智能化管理，提高生产效率和质量，降低成本，优化资源配置，提高客户满意度。",
        },
      ],
      total: 0,
      current: 1,
      maxPage: 1,
      pages: {
        pageSize: "6",
        pageNo: 1,
      },
      seach: {
        indus: "全部",
        pro: "全部",
      },
      sel: "",
      showIndus: false,
      showPro: false,
    };
  },
  created() {
    this.handleCase();
  },
  methods: {
    lunboleft() {
      if (this.current == 1) return;
      this.current = this.current - 1;
      this.pages.pageNo = this.current;
      this.handleCase();
    },
    lunboright() {
      if (this.current == 25) return;
      this.current = this.current + 1;
      this.pages.pageNo = this.current;
      this.handleCase();
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleAll1(item) {
      this.seach.indus = item;
    },
    handleTag1(item) {
      this.seach.indus = item;
    },

    handleAll2(item) {
      this.seach.pro = item;
    },
    handleTag2(item) {
      this.seach.pro = item;
    },

    handleCase() {
      const prams = this.pages;
      Newslist(Qs.stringify(prams)).then((res) => {
        this.total = res.data.total;
        // this.listcot = res.data.rows;
        this.maxPage = Math.ceil(this.total / this.pages.pageSize);
      });
    },
    handlePre(e) {
      console.log(e);
      this.pages.pageNo = e;
      this.handleCase();
    },
    handleNext(e) {
      console.log(e);
      this.pages.pageNo = e;
      this.handleCase();
    },
    handleIndus() {
      this.showPro = false;
      this.showIndus = !this.showIndus;
    },
    handlePro() {
      this.showIndus = false;
      this.showPro = !this.showPro;
    },

    handles(item) {
      this.seach.indus = item;
    },
    handleSelectPro(item) {
      this.seach.pro = item;
    },
  },
};
</script>
      
      <style lang="less"  scoped>

.aa:active {
background-color: #ff0000; /* 点击时背景变为红色 */
color: #ffffff; /* 文字变为白色 */
}

.apipe-cloud-course-container-case {
  padding-bottom: 10px;
  background: #f5f6f7;
  .top-banner {
    height: 600px;
    background-image: url("../../assets/newSite/case/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 16px;
      }
    }
  }
  .mobile-banner {
    height: 200px;
    background-image: url("../../assets/newSite/home/p3.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 12px;
      }
      .exp {
        margin-top: 0.5rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }

  .pc-case {
    margin-top: 30px;
    .pro-tit {
      text-align: left;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 10px;
    }
    .catcary {
      text-align: left;
      margin-top: 30px;
      .catcary-wrap {
        display: flex;
        font-size: 14px;
        color: #666666;
        margin-bottom: 30px;
        .left {
          display: flex;
          margin: 5px 0;
          .indus {
            margin-right: 10px;
            width: 60px;
          }
          .all {
            width: 50px;
            cursor: pointer;
            height: fit-content;
            text-align: center;
          }
          .act {
            background-color: rgba(20, 132, 255, 10%);
            color: rgba(20, 132, 255, 1);
          }
        }
        .types {
          flex-flow: wrap;
          display: flex;
          .tgs {
            margin: 5px 10px;
            cursor: pointer;
            padding: 0 6px;
          }

          .act {
            background-color: rgba(20, 132, 255, 10%);
            color: rgba(20, 132, 255, 1);
          }
        }
      }
      .content {
        min-height: 600px;
        margin-bottom: 30px;
        .content-warp {
          .itms {
            img {
              width: 100%;
              height: 200px;
            }
            .cot {
              // border:1px solid red;
              background: white;
              min-height: 400px;
              .des {
                font-size: 14px;
                color: #666666;
                margin: 6px 0;
              }
              .tit {
                font-size: 16px;
                margin-top: 10px;
                color: #333333;
              }
            }
          }
        }
      }
    }

    .pageNation {
      padding: 10px 0;
      .preAndNext {
        display: flex;
        align-items: center;
        .nums {
          margin: 0 10px;
        }
        .before,
        .after {
          display: inline-block;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 1px solid #ccc;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
        }
        .dis {
          // border: 1px solid #ccc;
          cursor: not-allowed !important;
        }
        .activ {
          border: 1px solid rgba(20, 132, 255, 1);
        }
      }
    }
  }
  .mobile-case {
    margin-top: 30px;
    .pro-tit {
      text-align: left;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 10px;
    }
    .opt {
      display: flex;
      font-size: 14px;
      position: relative;
      .indus {
        flex: 1;
        // padding: 10px 0;
        .boader {
          position: absolute;
          height: 200px;
          width: 100%;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
          left: 0;
          top: 30px;
          background: white;
          padding: 10px;
          display: flex;
          // justify-content: space-evenly;
          flex-flow: column;
          justify-content: space-evenly;
          .itms {
            // display: flex;
            // flex: 30%;
            padding: 10px;
          }
        }
      }
      .prod {
        flex: 1;
        // padding: 10px 0;

        .boader {
          position: absolute;
          height: 200px;
          width: 100%;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
          left: 0;
          top: 30px;
          background: white;
          padding: 10px;
          display: flex;
          // justify-content: space-evenly;
          flex-flow: wrap;
          // justify-content: space-evenly;
          .itms {
            // display: flex;
            width: 30%;
          }
        }
      }
    }
    .catcary {
      text-align: left;
      margin-top: 30px;
      .catcary-wrap {
        display: flex;
        font-size: 14px;
        color: #666666;
        margin-bottom: 30px;
        .left {
          display: flex;
          margin: 5px 0;
          .indus {
            margin-right: 10px;
            width: 60px;
          }
          .all {
            width: 50px;
            cursor: pointer;
            height: fit-content;
            text-align: center;
          }
          .act {
            background-color: rgba(20, 132, 255, 10%);
            color: rgba(20, 132, 255, 1);
          }
        }
        .types {
          flex-flow: wrap;
          display: flex;
          .tgs {
            margin: 5px 10px;
            cursor: pointer;
            padding: 0 6px;
          }

          .act {
            background-color: rgba(20, 132, 255, 10%);
            color: rgba(20, 132, 255, 1);
          }
        }
      }
      .content {
        min-height: 600px;
        // border: 1px solid red;
        margin-bottom: 30px;
        .content-warp {
          display: flex;
          flex-flow: wrap;
          justify-content: space-between;
          .itms {
            width: 100%;
            box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
            margin: 10px;
            padding: 10px;
            background: white;
            img {
              width: 100%;
              height: 200px;
            }
            .cot {
              padding: 10px;
              .des {
                font-size: 14px;
                color: #666666;
                margin: 6px 0;
              }
              .tit {
                font-size: 16px;
                color: #333333;
                margin: 10px 0;
              }
            }
          }
        }
      }
    }
    .pageNation {
      padding: 10px 0;
      .preAndNext {
        display: flex;
        align-items: center;
        justify-content: center;
        .nums {
          margin: 0 10px;
        }
        .before,
        .after {
          display: inline-block;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 1px solid #ccc;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
        }
        .dis {
          // border: 1px solid #ccc;
          cursor: not-allowed !important;
        }
        .activ {
          border: 1px solid rgba(20, 132, 255, 1);
        }
      }
    }
  }
}
</style>
      